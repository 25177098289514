<template>
  <v-container>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-tabs v-model="tabIndex">
        <v-tab v-for="item in items" :key="item.id">{{ item.tab }}</v-tab>
      </v-tabs>
      <v-tabs-items class="tab-content" v-model="tabIndex">
        <v-tab-item active>
          <subject-content
              class="tab-detail"
              :subject="detailSubject"
              :isNew="isCreate"
              :grade="listGrade"
          ></subject-content>
        </v-tab-item>
      </v-tabs-items>
      <v-row v-if="isCreate" class="align-center justify-center ma-0 pt-5">
        <v-btn color="primary" @click="handleSubmit(saveSubject)">Tạo môn học</v-btn>
      </v-row>
      <v-row v-else class="align-center justify-center ma-0 pt-5">
        <v-btn color="primary" @click="handleSubmit(updateSubject)">Cập nhật môn học</v-btn>
      </v-row>
    </ValidationObserver>
  </v-container>
</template>

<script>
import SubjectContent from "@/components/subject/SubjectContent.vue";

import {mapGetters, mapState} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";
import {
  GET_DETAIL_SUBJECT,
  UPDATE_SUBJECT,
  SAVE_SUBJECT,
  SET_DEFAULT_SUBJECT, GET_LIST_SUBJECTS,
} from "@/store/subject.module";
import {SUCCESS, ERROR} from "@/store/alert.module";
import mixin from "@/store/mixin";
import {GET_LIST_GRADE} from "@/store/grade.module";

export default {
  data() {
    return {
      slug: "",
      isCreate: true,
      tabIndex: 0,
      subject: {},
      items: [{id: 0, tab: "Thông tin chủ đề"}],
      headers: [
        {
          text: "id",
          value: "id",
        },
        {text: "Tên Chuyên mục", value: "name"},
      ],
    };
  },
  mixins: [mixin],
  components: {
    SubjectContent,
  },
  computed: {
    ...mapState({
      detailSubject: (state) => state.subject.subject
    }),
    ...mapGetters({
      listGrade: 'listGrade'
    })
  },
  watch: {
    detailSubject: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.subject = newVal;
      },
    },
  },
  created() {
    this.slug = this.$route.query.slug;
  },
  mounted() {
    this.$store.dispatch(SET_DEFAULT_SUBJECT, [{title: "Sửa môn học"}]);
    this.$store.dispatch(GET_LIST_GRADE)
    if (this.slug) {
      this.isCreate = false;
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Sửa môn học"}]);

      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_DETAIL_SUBJECT, {slug: this.slug})
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    } else {
      this.isCreate = true;
      this.$store.dispatch(SET_BREADCRUMB, [{title: "Thêm môn học"}]);
    }
  },
  methods: {
    updateSubject() {
      let payload = {
        id: this.subject.id,
        name: this.detailSubject.name,
        act: this.detailSubject.status ? 1 : 0,
        ord: this.detailSubject.order,
        img: this.detailSubject.img,
        grades: this.detailSubject.gradeId,
        color: this.detailSubject.color
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(UPDATE_SUBJECT, payload)
          .then((data) => {
            if (data.status) {
              this.$store.dispatch(SUCCESS, "Cập nhật môn học thành công!", {
                root: true,
              });
              this.$router.go(-1);
            } else {
              if (data.errorCode == 424) {
                this.$store.dispatch(ERROR, data.message, {
                  root: true,
                });
              } else {
                this.$store.dispatch(ERROR, "Cập nhật môn học thất bại!", {
                  root: true,
                });
              }
            }
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(ERROR, "Cập nhật môn học thất bại!", {
              root: true,
            });
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    saveSubject() {

      this.detailSubject.slug = this.sanitizeTitle(this.detailSubject.name)
      let payload = {
        name: this.detailSubject.name,
        slug: this.detailSubject.slug,
        act: this.detailSubject.status ? 1 : 0,
        ord: this.detailSubject.order,
        img: this.detailSubject.img,
        grades: this.detailSubject.gradeId,
        color: this.detailSubject.color
      };
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(SAVE_SUBJECT, payload)
          .then((data) => {
            if (data.status) {
              this.$store.dispatch(SUCCESS, "Thêm môn học thành công!", {
                root: true,
              });
              this.$router.go(-1);
            } else {
              this.$store.dispatch(ERROR, "Thêm môn học thất bại!", {
                root: true,
              });
            }
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(ERROR, "Thêm môn học thất bại!", {root: true});
            this.$store.dispatch(SET_LOADING, false);
          });
    },
  },
};
</script>

<style lang="scss">
.tab-detail {
  margin-top: 15px;
}
</style>
