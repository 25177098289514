<template>
  <v-container>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Tên môn học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="name" rules="required" v-slot="{ errors }">
          <hnr-input :text="subject.name" v-model="subject.name"></hnr-input>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Kích hoạt </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <ValidationProvider name="status" rules="required" v-slot="{ errors }">
          <v-switch v-model="subject.status"></v-switch>
          <span class="validate-error text-danger text-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>

      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Lớp học </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-select
            multiple
            v-model="subject.gradeId"
            :items="grade"
            item-text="name"
            item-value="id"
            label="Chọn lớp học"
            dense
            outlined></v-select>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Sắp xếp </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <hnr-input
            :text="convertNumberToString(subject.order)"
            v-model="subject.order"
        ></hnr-input>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Mã màu </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <vue-gpickr v-model="gradient" class="mb-10"/>
      </v-col>
    </v-row>
    <v-row class="align-center justify-center">
      <v-col cols="2" class="pa-1">
        <span> Banner ảnh </span>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-file-input
            class="pl-1"
            prepend-icon="mdi-camera"
            v-model="image"
            label="Tải ảnh"
            @change="onChangeFileUpload"
            @click:clear="clearImage"
        ></v-file-input>
        <div id="preview">
          <v-img :src="replace_image" aspect-ratio="1.7" width="50px" height="50px"></v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HnrInput from "@/components/elements/hnr-input";
import {SAVE_IMAGE} from "@/store/image.module";


// import { mapState } from "vuex";
// import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {SET_LOADING} from "@/store/loading.module";
// import {
//   GET_DETAIL_SUBJECT,
//   UPDATE_SUBJECT,
//   SAVE_SUBJECT,
// } from "@/store/subject.module";
// import { SUCCESS, ERROR } from "@/store/alert.module";
import {VueGpickr, LinearGradient} from 'vue-gpickr';

export default {
  props: {
    subject: Object,
    grade: Array
  },
  components: {
    HnrInput,
    VueGpickr
  },

  data() {
    const gradient = new LinearGradient({
      angle: 180,
      stops: [
        ['#1CC692', 0],
        ['#2A475D', 1]
      ]
    });
    return {
      image: {},
      show: true,
      gradient,
    };
  },
  computed: {
    replace_image() {
      if (this.subject) {
        return this.subject.img;
      }
      return "";
    },

  },
  watch: {
    gradient: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          const color = {
            'web':JSON.stringify(this.gradient.toRaw(), null, "  "),
            'mobile':this.gradient.toString()
          }
          this.subject.color = color
        }
      }
    },
    subject:{
      handler(val){
        if(val && val.color && val.color.web){
          this.gradient = new LinearGradient(JSON.parse(val.color.web))
        }
      }
    }
  },
  methods: {
    convertNumberToString(number) {
      if (number !== undefined) {
        return number?.toString();
      }
      return "0";
    },
    onChangeFileUpload() {
      this.$store.dispatch(SET_LOADING, true);
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(SAVE_IMAGE, formData).then((data) => {
        if (data.status == 1 && data.url) {
          this.subject.img = data.url;
          this.$store.dispatch(SET_LOADING, false);
        }
      });
    },
    clearImage() {
      this.subject.img = "";
      this.show = false;
    },
  },
};
</script>
